//  Hooks
import { useLayoutEffect, useRef, useEffect } from "react";
import { useTitle } from "../components/Hooks/useTitle";
// Styles
import styles from "../components/styles/AboutUs.module.scss";
// Gsap
import { gsap } from "gsap";

export default function AboutUs() {
  const ref = useRef(null);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useTitle("About Us | Unlock Cambodia");

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(".content", {
        opacity: 0,
        duration: 1,
        y: "1em",
        ease: "power",
      });
      gsap.to(".span", {
        clipPath: "polygon(0 100%, 0 0, 100% 0, 100% 100%)",
        duration: 1,
      });
    }, [ref]);
    return () => ctx.revert();
  }, []);

  return (
    <main className={styles.main} ref={ref}>
      <div className={`content ${styles.aboutUs}`}>
        <h1>
          Our <span className="span title-highlight">Story</span>
        </h1>
        <div>
          <p>
            Unlock Cambodia has been arranging specialist trips, tours and
            adventures since 2010. Our experienced tourism professionals have
            all lived and breathed Cambodia and know the country like the back
            of their hands.
          </p>
          <p>
            We have experience of arranging all types of activities, trips and
            services in Cambodia as well as in neighbouring countries of
            Vietnam, Loas and Thailand.
          </p>
          <p>
            Whether you’re traveling as an individual solo traveller or part of
            a larger specialist group our incredible guides have all angles
            covered.
          </p>
          <p> So come and let us Unlock Cambodia for you.</p>
        </div>
      </div>
    </main>
  );
}
